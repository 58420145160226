.app {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.groups-button-column {
  display: flex;
  flex-direction: column;
}

.groups-button {
  margin: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.groups-button>img {
  padding-right: 10px;
}

.btn-submit {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;

}

.group-details {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  padding: 16px;
}

.group-details-wrapper {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0px #46454536;
}

.group-details-points {
  color: #5f5f5f;
  padding-right: 13px;
}

.question-title {
  padding: 10px;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
  margin-bottom: 26px;
}





.reset-button {
  float: right;
  margin-top: 10px;
  ;
}

.icon-button {
  border-radius: 10px;

  height: 35px;
  width: 35px;
  transition: box-shadow cubic-bezier(0, 0, 0.18, 1.31) 0.5s;
}

.icon-button.active {
  box-shadow: 0 0 11px #a7a7a7;
}

.quiz-body,
form {
  width: 100%;
}

.question-wrap {
  margin-top: 1;
  margin-top: 100px;
}

.quiz-body {

  height: 100%;
  overflow: auto;

  max-width: 35rem;
  padding: 10px;
}

.animals {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  margin-bottom: 7px;
}

.h3 {
  padding-bottom: 17px;
}

.submitted-group {
  text-align: center;
  font-weight: 100;
  font-size: 16pt;
  padding-top: 14px;

}

.submitted-group>svg {
  padding-right: 10px;
  ;
}

.bi {
  margin-top: -3px;
}

.paused {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #353535;

}


.color-selection-button {
  background-color: rgb(244, 67, 54);
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 20px;
  cursor: pointer;
    box-shadow: 0 0 5px #797878;
  margin: 3px;
}


.color-selection-button-selected {
  background-color: rgb(244, 67, 54);
  height: 30px;
  width: 30px;
  display: inline-block;
  border-radius: 20px;
  border: 3px solid white;

  cursor: pointer;
  margin: 3px;
}
.color-selection-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  
  justify-content: center;
}